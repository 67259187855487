<template>
  <div class="app-container">
    <CrudTable entity="KassaUser" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'code', label: 'login.code' },
        'name',
        {
          field: 'kassaEditAllowedYN',
          label: 'user.kassaEditAllowedYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        {
          field: 'isRestaurantManagerYN',
          label: 'employee.is_restaurant_manager',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  }
};
</script>

<style scoped></style>
